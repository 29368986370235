<template>
    <page-content>
        <a-card class="data-card" title="待处理">

            <div class="data-content">

                <div class="item clickable" @click="toOrder(0)">
                    <div class="title">待付款</div>
                    <div class="value">{{overview.unpaidOrder}}</div>
                </div>

                <div class="item clickable" @click="toOrder(1)">
                    <div class="title">待发货</div>
                    <div class="value">{{overview.paidOrder}}</div>
                </div>

                <div class="item clickable" @click="toOrder(4)">
                    <div class="title">售后</div>
                    <div class="value">{{overview.refundOrder}}</div>
                </div>

            </div>

        </a-card>
        <a-card class="data-card" title="今日数据">

            <div class="data-content">


                <div class="item">
                    <div class="title">支付金额</div>
                    <div class="value">￥{{overview.todayPayMoney/100}}</div>
                </div>

                <div class="item">
                    <div class="title">支付订单</div>
                    <div class="value">{{overview.todayPayOrder}}</div>
                </div>

                <div class="item">
                    <div class="title">购买人数</div>
                    <div class="value">{{overview.todayPayUser}}</div>
                </div>

            </div>

        </a-card>

        <a-card class="data-card" title="数据概览" hidden>

            <div class="data-content">

                <div class="item">
                    <div class="title">商品数量</div>
                    <div class="value">{{overview.totalGoods}}</div>
                </div>

                <div class="item">
                    <div class="title">累计订单</div>
                    <div class="value">{{overview.totalOrder}}</div>
                </div>

                <div class="item">
                    <div class="title">累计支付金额</div>
                    <div class="value">￥{{overview.totalPayMoney/100}}</div>
                </div>

            </div>

        </a-card>

    </page-content>
</template>
<script>
    export default {
        data(){
            return {
                overview:{}
            }
        },
        created() {
            this.$get("admin/home/mall/overview").then(suc=>{
                this.overview=suc.data
            })
        },
        methods:{
            toOrder(status){
                this.$router.push("/mall/order?status="+status)
            }
        }
    }
</script>
<style lang="less" scoped>
    .data-card {
        margin-bottom: 20px;

        .data-content {
            display: flex;

            .item{
                text-align: center;
                width: 160px;
                margin: 0 10px;
                padding: 15px 0;
                border-radius: 5px;
            }

            .title{
                color: #666666;
                font-size: 16px;
            }

            .value{
                color: #333333;
                font-weight: 600;
                font-size: 24px;
                margin-top: 10px;
            }
        }

        .clickable{
            cursor: pointer;
        }

        .clickable:hover{
            background: #F8F8F8;
        }
    }

</style>
